import {
    ComponentType,
    CSSProperties,
    useState,
    useEffect,
    MouseEventHandler,
} from "react"
import { addPropertyControls, ControlType, withCSS, useRouter } from "framer"
import { HTMLMotionProps, isBrowser } from "framer-motion"
import { auth } from "https://cdn.framerauth.com/scripts/framerauth-sdk@beta.js"

interface Props extends Omit<HTMLMotionProps<"div">, "layout"> {
    links: any[]
    primaryButton: any
    secondaryButton: any
    avatar: any
    layout: "horizontal" | "vertical"
    align: "flex-start" | "center" | "flex-end" | "stretch"
    style: CSSProperties
    store?: any
}

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth any-prefer-auto
 * @framerSupportedLayoutHeight auto
 */
const AuthWidget: ComponentType<Props> = withCSS<Props>(function AuthWidget({
    links,
    primaryButton,
    secondaryButton,
    avatar,
    layout,
    align,
    store,
}: Props) {
    const router = useRouter()
    const [menuOpen, setMenuOpen] = useState(false)

    const {
        paddingPerSide: primaryButtonPaddingPerSide,
        paddingTop: primaryButtonPaddingTop,
        paddingRight: primaryButtonPaddingRight,
        paddingBottom: primaryButtonPaddingBottom,
        paddingLeft: primaryButtonPaddingLeft,
        padding: primaryButtonPadding,
        borderRadius: primaryButtonBorderRadius,
        borderObject: primaryButtonBorderObject,
        shadowObject: primaryButtonShadowObject,
    } = primaryButton

    const primaryButtonPaddingValue = primaryButtonPaddingPerSide
        ? `${primaryButtonPaddingTop}px ${primaryButtonPaddingRight}px ${primaryButtonPaddingBottom}px ${primaryButtonPaddingLeft}px`
        : `${primaryButtonPadding}px ${primaryButtonPadding}px ${primaryButtonPadding}px ${primaryButtonPadding}px`

    const primaryButtonShadowStyles = primaryButton.shadowObject
        ? `${primaryButtonShadowObject.shadowX}px ${primaryButtonShadowObject.shadowY}px ${primaryButtonShadowObject.shadowBlur}px ${primaryButtonShadowObject.shadowColor}`
        : null

    const primaryButtonBorderStyles = primaryButton.borderObject
        ? `inset 0 0 0 ${primaryButtonBorderObject.borderWidth}px ${primaryButtonBorderObject.borderColor}`
        : null

    const {
        paddingPerSide: secondaryButtonPaddingPerSide,
        paddingTop: secondaryButtonPaddingTop,
        paddingRight: secondaryButtonPaddingRight,
        paddingBottom: secondaryButtonPaddingBottom,
        paddingLeft: secondaryButtonPaddingLeft,
        padding: secondaryButtonPadding,
        borderRadius: secondaryButtonBorderRadius,
        borderObject: secondaryButtonBorderObject,
        shadowObject: secondaryButtonShadowObject,
    } = secondaryButton

    const secondaryButtonPaddingValue = secondaryButtonPaddingPerSide
        ? `${secondaryButtonPaddingTop}px ${secondaryButtonPaddingRight}px ${secondaryButtonPaddingBottom}px ${secondaryButtonPaddingLeft}px`
        : `${secondaryButtonPadding}px ${secondaryButtonPadding}px ${secondaryButtonPadding}px ${secondaryButtonPadding}px`

    // Button Box Shadow Styles
    const secondaryButtonShadowStyles = secondaryButton.shadowObject
        ? `${secondaryButtonShadowObject.shadowX}px ${secondaryButtonShadowObject.shadowY}px ${secondaryButtonShadowObject.shadowBlur}px ${secondaryButtonShadowObject.shadowColor}`
        : null

    const secondaryButtonBorderStyles = secondaryButton.borderObject
        ? `inset 0 0 0 ${secondaryButtonBorderObject.borderWidth}px ${secondaryButtonBorderObject.borderColor}`
        : null

    const {
        paddingPerSide: avatarPaddingPerSide,
        paddingTop: avatarPaddingTop,
        paddingRight: avatarPaddingRight,
        paddingBottom: avatarPaddingBottom,
        paddingLeft: avatarPaddingLeft,
        padding: avatarPadding,
        borderRadius: avatarBorderRadius,
        borderObject: avatarBorderObject,
        shadowObject: avatarShadowObject,
    } = avatar

    const avatarPaddingValue = avatarPaddingPerSide
        ? `${avatarPaddingTop}px ${avatarPaddingRight}px ${avatarPaddingBottom}px ${avatarPaddingLeft}px`
        : `${avatarPadding}px ${avatarPadding}px ${avatarPadding}px ${avatarPadding}px`

    // Button Box Shadow Styles
    const avatarShadowStyles = avatar.shadowObject
        ? `${avatarShadowObject.shadowX}px ${avatarShadowObject.shadowY}px ${avatarShadowObject.shadowBlur}px ${avatarShadowObject.shadowColor}`
        : null

    const avatarBorderStyles = avatar.borderObject
        ? `inset 0 0 0 ${avatarBorderObject.borderWidth}px ${avatarBorderObject.borderColor}`
        : null

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const loggedInOrEditor = () => {
        return (store && store.isAuthenticated) || window?.["Framer"]
    }

    const loggedOutOrEditor = () => {
        return (store && !store.isAuthenticated) || window?.["Framer"]
    }

    const displayLink = (link) => {
        if (link.display === "none") {
            return false
        } else if (link.display === "allways" || window?.["Framer"]) {
            return true
        } else if (link.display === "loggedIn") {
            return store && store.isAuthenticated
        } else if (link.display === "loggedOut") {
            return store && !store.isAuthenticated
        }
    }

    const handleClick = (item) => {
        if (!item) return

        const { linkType, link, action } = item

        if (linkType === "link") {
            window.location.href = link
        } else {
            if (action === "signOut") {
                auth.signOut("/")
            }
        }
    }

    function dynamicBoxShadow(...shadows: Array<string | null>) {
        const output: string[] = []
        shadows.forEach((shadow) => shadow && output.push(shadow))
        return output.join(", ")
    }

    const primaryButtonStyle = {
        ...buttonStyle,
        ...primaryButton.font,
        padding: primaryButtonPaddingValue,
        borderRadius: primaryButtonBorderRadius,
        background: primaryButton.fill,
        color: primaryButton.color,
        boxShadow: dynamicBoxShadow(
            primaryButtonShadowStyles,
            primaryButtonBorderStyles
        ),
    }

    const secondaryButtonStyle = {
        ...buttonStyle,
        ...secondaryButton.font,
        padding: secondaryButtonPaddingValue,
        borderRadius: secondaryButtonBorderRadius,
        background: secondaryButton.fill,
        color: secondaryButton.color,
        boxShadow: dynamicBoxShadow(
            secondaryButtonShadowStyles,
            secondaryButtonBorderStyles
        ),
    }

    return (
        <div
            style={{
                ...containerStyle,
                flexDirection: layout === "vertical" ? "column" : "row",
            }}
        >
            {links
                .filter(
                    (link) =>
                        displayLink(link) &&
                        (link.location === "nav" || layout === "vertical")
                )
                .map((item, index) => (
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            position: "relative",
                            alignSelf: align,
                        }}
                    >
                        <button
                            onClick={() => handleClick(item)}
                            style={
                                item.style === "primary"
                                    ? primaryButtonStyle
                                    : secondaryButtonStyle
                            }
                        >
                            {item.label}
                        </button>
                    </div>
                ))}

            {loggedInOrEditor() &&
                layout === "horizontal" &&
                avatar.display && (
                    <div style={relativeStyle}>
                        <div>
                            <button
                                type="button"
                                style={avatarWrapperStyle}
                                onClick={toggleMenu}
                            >
                                <div
                                    style={{
                                        ...avatarStyle,
                                        ...avatar.font,
                                        height: `${avatar.size}px`,
                                        width: `${avatar.size}px`,
                                        borderRadius: avatarBorderRadius,
                                        fontWeight: avatar.fontWeight,
                                        background: avatar.fill,
                                        color: avatar.color,
                                        boxShadow: dynamicBoxShadow(
                                            avatarShadowStyles,
                                            avatarBorderStyles
                                        ),
                                    }}
                                >
                                    {store?.user?.first_name
                                        ?.charAt(0)
                                        ?.toUpperCase() || "A"}
                                </div>
                            </button>
                        </div>

                        {menuOpen && (
                            <div
                                style={dropdownStyle}
                                onMouseLeave={() => setMenuOpen(false)}
                            >
                                <div
                                    style={{
                                        ...menuItemStyle,
                                        cursor: "default",
                                        borderBottom: "1px solid #F3F4F6",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            marginBottom: "4px",
                                        }}
                                    >
                                        {store?.user?.first_name}
                                    </div>
                                    <div>{store?.user?.email}</div>
                                </div>

                                {links
                                    .filter((link) => link.location === "menu")
                                    .map((item, index) => (
                                        <a
                                            key={index}
                                            onClick={() => handleClick(item)}
                                            style={{
                                                ...menuItemStyle,
                                                textAlign: "center",
                                            }}
                                            target={
                                                item.newTab ? "_blank" : "_self"
                                            }
                                        >
                                            {item.label}
                                        </a>
                                    ))}
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
}, [])

const containerStyle = {
    display: "flex",
    width: "100%",
    gap: "12px",
    justifyContent: "center",
    alignItems: "center",
}

const relativeStyle = {
    position: "relative",
}

const avatarWrapperStyle = {
    display: "flex",
    border: 0,
    padding: "0",
    backgroundColor: "transparent",
    fontSize: "16px",
    outline: "none",
    cursor: "pointer",
}

const avatarStyle = {
    display: "flex",
    fontSize: "16px",
    justifyContent: "center",
    alignItems: "center",
}

const dropdownStyle = {
    position: "absolute",
    right: "0",
    zIndex: "999999",
    marginTop: "8px", // 0.5rem
    minWidth: "192px", // 12rem
    background: "#fff",
    borderRadius: "6px", // 0.375rem
    padding: "4px", // 0.25rem
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    outline: "none",
}

const menuItemStyle = {
    display: "block",
    padding: "12px 16px", // 0.5rem 1rem
    fontSize: "13px", // approximately small
    color: "#4A5568",
    textDecoration: "none",
    cursor: "pointer",
}

const buttonStyle = {
    border: 0,
    width: "100%",
    fontSize: "16px",
    cursor: "pointer",
    whiteSpace: "nowrap",
}

const paddingPropertyControls = {
    padding: {
        title: "Padding",
        type: ControlType.FusedNumber,
        toggleKey: "paddingPerSide",
        toggleTitles: ["Padding", "Padding per side"],
        defaultValue: 8,
        valueKeys: [
            "paddingTop",
            "paddingRight",
            "paddingBottom",
            "paddingLeft",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
}

const sizePropertyControls = {
    size: {
        title: "Size",
        type: ControlType.Number,
        defaultValue: 34,
    },
}

const buttonPropertycontrols = {
    font: {
        type: ControlType.Font,
        title: "Font",
        controls: "extended",
    },

    fill: {
        title: "Fill",
        type: ControlType.Color,
        defaultValue: "#333",
    },
    color: {
        title: "Text",
        type: ControlType.Color,
        defaultValue: "#FFF",
    },
    borderRadius: {
        title: "Radius",
        type: ControlType.Number,
        displayStepper: true,
        min: 0,
        defaultValue: 8,
    },
    borderObject: {
        type: ControlType.Object,
        title: "Border",
        optional: true,
        controls: {
            borderWidth: {
                title: "Width",
                type: ControlType.Number,
                displayStepper: true,
                defaultValue: 1,
            },
            borderColor: {
                title: "Color",
                type: ControlType.Color,
                defaultValue: "rgba(200,200,200,0.5)",
            },
        },
    },
    shadowObject: {
        type: ControlType.Object,
        title: "Shadow",
        optional: true,
        controls: {
            shadowColor: {
                title: "Color",
                type: ControlType.Color,
                defaultValue: "rgba(0,0,0,0.25)",
            },
            shadowX: {
                title: "Shadow X",
                type: ControlType.Number,
                min: -100,
                max: 100,
                defaultValue: 0,
            },
            shadowY: {
                title: "Shadow Y",
                type: ControlType.Number,
                min: -100,
                max: 100,
                defaultValue: 2,
            },
            shadowBlur: {
                title: "Shadow B",
                type: ControlType.Number,
                min: 0,
                max: 100,
                defaultValue: 4,
            },
        },
    },
}

const basePropertyControls = {
    links: {
        title: "Links",
        type: ControlType.Array,
        control: {
            type: ControlType.Object,
            controls: {
                label: {
                    title: "Label",
                    type: ControlType.String,
                    defaultValue: "Button",
                },
                linkType: {
                    type: ControlType.Enum,
                    defaultValue: "link",
                    options: ["link", "action"],
                    optionTitles: ["Link", "Action"],
                },
                link: {
                    title: "Link",
                    type: ControlType.Link,
                    hidden: (props) => props.linkType !== "link",
                },
                newTab: {
                    title: "New Tab",
                    type: ControlType.Boolean,
                    defaultValue: false,
                    hidden: (props) => props.linkType !== "link",
                },
                action: {
                    type: ControlType.Enum,
                    defaultValue: "signOut",
                    options: ["signOut"],
                    optionTitles: ["Sign out"],
                    hidden: (props) => props.linkType !== "action",
                },
                location: {
                    type: ControlType.Enum,
                    defaultValue: "nav",
                    options: ["nav", "menu"],
                    optionTitles: ["Navigation", "Menu"],
                },
                style: {
                    type: ControlType.Enum,
                    defaultValue: "secondary",
                    options: ["primary", "secondary"],
                    optionTitles: ["Primary", "Secondary"],
                },
                display: {
                    type: ControlType.Enum,
                    defaultValue: "allways",
                    options: ["allways", "loggedIn", "loggedOut", "none"],
                    optionTitles: [
                        "Allways",
                        "Logged In",
                        "Logged Out",
                        "None",
                    ],
                },
            },
        },
    },
    primaryButton: {
        title: "Primary",
        type: ControlType.Object,
        controls: {
            ...buttonPropertycontrols,
            ...paddingPropertyControls,
        },
    },
    secondaryButton: {
        title: "Secondary",
        type: ControlType.Object,
        controls: {
            ...buttonPropertycontrols,
            ...paddingPropertyControls,
        },
    },
    avatar: {
        title: "Avatar",
        type: ControlType.Object,
        controls: {
            ...buttonPropertycontrols,
            ...sizePropertyControls,
            display: {
                title: "Display",
                type: ControlType.Boolean,
                defaultValue: true,
            },
        },
    },
    layout: {
        title: "Layout",
        type: ControlType.Enum,
        options: ["horizontal", "vertical"],
        displaySegmentedControl: true,
    },
    align: {
        title: "Align",
        type: ControlType.Enum,
        segmentedControlDirection: "vertical",
        options: ["flex-start", "center", "flex-end", "stretch"],
        optionTitles: ["Start", "Center", "End", "Stretch"],
        defaultValue: "stretch",
        hidden: (props) => props.layout === "horizontal",
    },
}

addPropertyControls(AuthWidget, basePropertyControls)

AuthWidget.defaultProps = {
    layout: "vertical",
    avatar: {
        fill: "#7421FC",
    },
    links: [
        {
            label: "Sign In",
            location: "nav",
            linkType: "link",
            link: "",
            newTab: false,
            display: "loggedOut",
        },
        {
            label: "Sign Up",
            location: "nav",
            linkType: "link",
            link: "",
            newTab: false,
            display: "loggedOut",
        },
        {
            label: "Sign out",
            location: "menu",
            linkType: "action",
            link: "",
            action: "signOut",
            newTab: true,
            display: "loggedIn",
        },
    ],
}

export default AuthWidget
export { basePropertyControls }
